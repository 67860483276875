import { environment as original } from './environment.dev';

export const environment = {
  ...original,
  name: 'Oongalee ExcuseMe - Boston Pizza - BP - DEV',
  machineName: 'boston-pizza.bp.dev',
  featureToggle: {
    ...original.featureToggle,
    withCallWaiterRequestBill: true,
    tabBarAutoHide: false,
    idleDelay: 30,
  },
};
